import React, { useState, useEffect } from "react";
import axios from "axios";

const ImageSection = ({ imageId, image, formToken, chatId }) => {
  const [imageUrl, setImageUrl] = useState(image);
  const [loading, setLoading] = useState(!image);

  useEffect(() => {
    if (!imageUrl) {
      // Function to fetch image using the prompt
      const fetchImage = async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            `/api/v1/chats/${chatId}/generate_image`,
            { chat_image_id: imageId },
            {
              headers: {
                Authorization: `Bearer ${formToken}`,
              },
            }
          );
          // debugger;
          setImageUrl(response.data.imageUrl);
        } catch (error) {
          console.error("Error generating image:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchImage();
    }
  }, [imageUrl, imageId, formToken, chatId]);

  return (
    <div className="relative">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <img src={imageUrl} alt="Story illustration" className="rounded-lg" />
      )}
    </div>
  );
};

export default ImageSection;
