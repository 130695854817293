import React from "react";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";

const Profile = ({ role, user }) => {
  const avatarSrc =
    role === "assistant"
      ? "/tybee-avatar.jpg"
      : user?.avatar || "https://via.placeholder.com/40";

  return (
    <img
      src={avatarSrc}
      alt={`${role === "assistant" ? "Assistant" : "User"} Avatar`}
      className="rounded-full w-8 h-8"
    />
  );
};

export const Message = ({ message, user, onFollowUpClick }) => {
  const isUser = message.role === "user";

  return (
    <div className={`flex flex-col sm:flex-row ${isUser ? "sm:justify-end" : "sm:justify-start"} mb-4`}>
      <div className={`flex items-center ${isUser ? "justify-end" : "justify-start"} mb-2 sm:mb-0 ${isUser ? "sm:order-2" : "sm:order-1"}`}>
        <Profile role={isUser ? "user" : "assistant"} user={user} />
      </div>
      <div className={`w-full sm:w-auto sm:max-w-[70%] ${isUser ? "sm:order-1" : "sm:order-2"} sm:mx-2`}>
        {isUser ? (
          <UserMessage message={message} />
        ) : (
          <AssistantMessage message={message} onFollowUpClick={onFollowUpClick} />
        )}
      </div>
    </div>
  );
};
