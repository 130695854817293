import React, { useState, useEffect } from "react";
import axios from "axios";
import ImageSection from "./ImageSection";

const StoryTellingChat = ({ formToken, onSaveChat, chatId, chatStory }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storyParts, setStoryParts] = useState(chatStory?.storyParts || []);
  const [chatStoryId, setChatStoryId] = useState(chatStory?.id || null);
  const [userPrompt, setUserPrompt] = useState(chatStory?.prompt || '');

  const handleSendStoryPrompt = async (prompt) => {
    setIsSubmitting(true);
    setUserPrompt(prompt);
    try {
      const queryParams = new URLSearchParams({ prompt }).toString();

      const response = await axios.post(
        `/api/v1/chats/${chatId}/tell_story?${queryParams}`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${formToken}`,
          },
        }
      );
      setChatStoryId(response.data.id);
      const storySegments = response.data.sections;
      setStoryParts(storySegments);
      onSaveChat();
    } catch (error) {
      console.error("Error generating story:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (chatStory) {
      setStoryParts(chatStory.storyParts);
      setChatStoryId(chatStory.id);
      setUserPrompt(chatStory.prompt);
    }
  }, [chatStory]);

  return (
    <div>
      {!storyParts.length && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const prompt = e.target.prompt.value;
            handleSendStoryPrompt(prompt);
          }}
        >
          <label htmlFor="prompt" className="block mb-2 text-lg font-medium bg-gray-100 p-2 rounded">
            What question or topic do you want me to write a story about?
          </label>
          <textarea
            id="prompt"
            name="prompt"
            className="w-full p-2 border rounded"
            rows={3}
            placeholder="Type your question or topic here..."
            disabled={isSubmitting}
          ></textarea>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Generating Story..." : "Submit"}
          </button>
        </form>
      )}

      {storyParts.length > 0 && (
        <div className="mt-6">
          <div className="mb-4 bg-blue-500 text-white p-3 rounded-lg">
            <h4 className="font-bold">Original Question:</h4>
            <p>{userPrompt}</p>
          </div>
          {storyParts.map((part, index) => (
            <div key={index} className="mb-4">
              <p className="mb-2 text-2xl bg-green-500 text-white p-2 rounded-lg">{part.content}</p>
              <ImageSection 
                imageId={part.imageId || ''} 
                image={part.image || ''} 
                formToken={formToken} 
                chatId={chatId} 
                chatStoryId={chatStoryId} 
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StoryTellingChat;
