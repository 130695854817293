import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const StripeWrapper = ({ familyId, formToken, initialPriceId }) => {
  const [priceId, setPriceId] = useState(initialPriceId);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    async function initializeCheckout() {
      try {
        const response = await fetch("/subscriptions/create.json", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": formToken,
          },
          body: JSON.stringify({
            price_id: priceId,
            family_id: familyId,
          }),
        });

        const data = await response.json();

        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          console.error("No client secret returned from the backend");
        }
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    }

    initializeCheckout();
  }, [priceId, familyId, formToken]);

  useEffect(() => {
    if (clientSecret && stripePromise) {
      stripePromise.then((stripe) => {
        stripe.initEmbeddedCheckout({
          clientSecret,
        }).then((checkout) => {
          checkout.mount("#checkout");
        }).catch((error) => {
          console.error("Error initializing embedded checkout", error);
        });
      });
    }
  }, [clientSecret]);

  const handlePriceChange = (newPriceId) => {
    setPriceId(newPriceId);
  };

  return (
    <Elements stripe={stripePromise}>
      <div>
        <div className="hidden">
          {/* Example button for switching price plan */}
          <button onClick={() => handlePriceChange("new_price_id")}>
            Switch to Yearly Plan
          </button>

          {/* Example slider for adjusting donation */}
          <input
            type="range"
            min="10"
            max="100"
            step="10"
            onChange={(e) => handlePriceChange(`price_id_based_on_${e.target.value}`)}
          />
          </div>

        <div id="checkout"></div>
      </div>
    </Elements>
  );
};

export default StripeWrapper;
