import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FaQuestionCircle } from 'react-icons/fa';

const AssistantMessage = ({ message, onFollowUpClick }) => {
  return (
    <div className="w-full">
      <div className="bg-green-100 text-green-900 p-3 rounded-lg">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.explanation}</ReactMarkdown>  
      </div>
      
      {message.equations && message.equations.length > 0 && (
        <div className="mt-2 bg-green-100 text-green-900 p-3 rounded-lg">
          <h4 className="font-bold">Equations:</h4>
          <ul className="list-disc pl-4">
            {message.equations.map((equation, index) => (
              <li key={index}>{equation}</li>
            ))}
          </ul>
        </div>
      )}

      {message.examples && message.examples.length > 0 && (
        <div className="mt-2 bg-green-100 text-green-900 p-3 rounded-lg">
          <h4 className="font-bold">Examples:</h4>
          <ul className="list-disc pl-4">
            {message.examples.map((example, index) => (
              <li key={index}>{example}</li>
            ))}
          </ul>
        </div>
      )}

      {message.follow_up && (
        <div className="mt-2 bg-green-100 p-3 rounded-lg">
          <h4 className="font-bold text-green-800 mb-2">Follow-up Questions:</h4>
          <ul className="list-none pl-0">
            {message.follow_up.map((question, index) => (
              <li key={index} className="mb-2">
                <button
                  onClick={() => onFollowUpClick(question)}
                  className="flex items-center text-left text-green-700 hover:text-green-900 transition-colors duration-200"
                >
                  <FaQuestionCircle className="mr-2" />
                  <span>{question}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      
      {message.note && (
        <div className="mt-2 italic">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.note}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default AssistantMessage;
