import React, { useState, useRef, useEffect } from "react";
import { HiOutlinePaperClip } from "react-icons/hi2";
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleUp } from "react-icons/fa";

const MessageInput = ({ handleSubmit, input, setInput, handleFileChange, showWork, messageCount = 0, disabled }) => {
  const [filePreview, setFilePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCameraActive, setIsCameraActive] = useState(false);
  const textareaRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (isCameraActive) {
      startCamera();
    }
  }, [isCameraActive]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [input]);

  const handleFileChangeInternal = (file) => {
    if (file) {
      const fileType = file.type;
      if (fileType === "image/png" || fileType === "image/jpeg") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
        handleFileChange(file);
        setErrorMessage("");
      } else {
        setErrorMessage("Only PNG and JPG files are allowed.");
      }
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { ideal: "environment" },
        }
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      const aspectRatio = 8.5 / 11; // 8.5" x 11" paper aspect ratio
      canvas.height = video.videoHeight;
      canvas.width = canvas.height * aspectRatio;

      const context = canvas.getContext("2d");

      const cropWidth = video.videoHeight * aspectRatio;
      const cropX = (video.videoWidth - cropWidth) / 2;

      context.drawImage(video, cropX, 0, cropWidth, video.videoHeight, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        const newCapturedFile = new File([blob], "captured_image.jpg", {
          type: "image/jpeg",
        });
        setFilePreview(URL.createObjectURL(newCapturedFile));
        handleFileChange(newCapturedFile); // Pass the file to the parent component
      });

      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setIsCameraActive(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
      setFilePreview(null);
    }
  };

  let paperClip = null;
  if (!showWork) {
    paperClip = (
      <button
        onClick={() => document.getElementById("file-upload").click()}
        className="flex items-center justify-center h-8 w-8 rounded-full hover:bg-gray-200"
      >
        <HiOutlinePaperClip size={20} />
      </button>
    );
  }

  return (
    <div className="message-input-container">
      {isCameraActive && (
        <div className="video-container mb-4" style={{ maxWidth: '100%', margin: '0 auto' }}>
          <video
            ref={videoRef}
            className="border rounded object-cover"
            style={{ height: "360px", width: "100%", maxWidth: "270px" }}
            playsInline
          ></video>
          <button
            type="button"
            onClick={captureImage}
            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Capture Image
          </button>
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        </div>
      )}
      {filePreview && (
        <div className="p-2 bg-gray-200 rounded-t">
          <div className="inline-block relative">
            <button
              onClick={() => {
                setFilePreview(null);
                handleFileChange(null);
              }}
              className="text-sm text-red-500 absolute right-0 top-[-8px] hover:text-red-700 bg-white rounded-full cursor-pointer"
            >
              <IoMdCloseCircle size={20} />
            </button>
            <img
              src={filePreview}
              alt="Selected"
              className="w-16 h-16 object-cover rounded mr-2"
            />
          </div>
        </div>
      )}
      {!isCameraActive && showWork && messageCount < 1 && (
        <button
        type="button"
        onClick={() => setIsCameraActive(true)}
        className="w-full mb-4 py-16 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="64"
            height="64"
            viewBox="0 0 260 260"
            xmlSpace="preserve"
            className="mb-2"
          >
            <g
              style={{
                stroke: "none",
                fill: "black",
              }}
              transform="scale(2.9 2.9)"
            >
              <path d="M 36.123 47.688 c -0.256 0 -0.512 -0.098 -0.707 -0.293 c -0.391 -0.391 -0.391 -1.023 0 -1.414 l 6.451 -6.451 c 0.391 -0.391 1.023 -0.391 1.414 0 s 0.391 1.023 0 1.414 l -6.451 6.451 C 36.635 47.591 36.379 47.688 36.123 47.688 z" />
              <path d="M 46.572 44.463 c -0.256 0 -0.512 -0.098 -0.707 -0.293 c -0.391 -0.391 -0.391 -1.023 0 -1.414 l 3.037 -3.037 c 0.391 -0.391 1.023 -0.391 1.414 0 s 0.391 1.023 0 1.414 l -3.037 3.037 C 47.084 44.365 46.828 44.463 46.572 44.463 z" />
              <path d="M 36.312 54.724 c -0.256 0 -0.512 -0.098 -0.707 -0.293 c -0.391 -0.391 -0.391 -1.023 0 -1.414 l 7.437 -7.438 c 0.391 -0.391 1.023 -0.391 1.414 0 s 0.391 1.023 0 1.414 l -7.437 7.438 C 36.823 54.626 36.567 54.724 36.312 54.724 z" />
              <path d="M 45 72.044 c -12.428 0 -22.54 -10.111 -22.54 -22.539 c 0 -12.429 10.111 -22.54 22.54 -22.54 c 12.428 0 22.539 10.111 22.539 22.54 C 67.539 61.933 57.428 72.044 45 72.044 z M 45 28.965 c -11.326 0 -20.54 9.214 -20.54 20.54 c 0 11.325 9.214 20.539 20.54 20.539 c 11.325 0 20.539 -9.214 20.539 -20.539 C 65.539 38.179 56.325 28.965 45 28.965 z" />
              <path d="M 86.018 18.81 H 64.651 v -4.878 c 0 -2.278 -1.854 -4.131 -4.131 -4.131 H 29.479 c -2.278 0 -4.131 1.853 -4.131 4.131 v 4.878 H 3.982 C 1.786 18.81 0 20.596 0 22.792 v 53.425 c 0 2.196 1.786 3.982 3.982 3.982 h 82.036 c 2.196 0 3.982 -1.786 3.982 -3.982 V 22.792 C 90 20.596 88.214 18.81 86.018 18.81 z M 27.348 13.932 c 0 -1.175 0.956 -2.131 2.131 -2.131 h 31.041 c 1.175 0 2.131 0.956 2.131 2.131 v 4.878 H 27.348 V 13.932 z M 88 76.217 c 0 1.093 -0.89 1.982 -1.982 1.982 H 3.982 C 2.889 78.199 2 77.31 2 76.217 V 22.792 c 0 -1.093 0.889 -1.982 1.982 -1.982 h 82.036 c 1.093 0 1.982 0.889 1.982 1.982 V 76.217 z" />
              <path d="M 78.023 35.504 c -2.598 0 -4.711 -2.113 -4.711 -4.711 s 2.113 -4.711 4.711 -4.711 s 4.711 2.113 4.711 4.711 S 80.621 35.504 78.023 35.504 z M 78.023 28.083 c -1.495 0 -2.711 1.216 -2.711 2.711 s 1.216 2.711 2.711 2.711 s 2.711 -1.216 2.711 -2.711 S 79.519 28.083 78.023 28.083 z" />
              <path d="M 45 65.505 c -8.822 0 -16 -7.178 -16 -16 c 0 -8.823 7.178 -16 16 -16 s 16 7.178 16 16 C 61 58.327 53.822 65.505 45 65.505 z M 45 35.504 c -7.72 0 -14 6.281 -14 14 s 6.28 14 14 14 s 14 -6.28 14 -14 S 52.72 35.504 45 35.504 z" />
              <path d="M 14.366 35.504 h -3.915 c -2.598 0 -4.711 -2.113 -4.711 -4.711 s 2.113 -4.711 4.711 -4.711 h 3.915 c 2.598 0 4.711 2.113 4.711 4.711 S 16.963 35.504 14.366 35.504 z M 10.451 28.083 c -1.495 0 -2.711 1.216 -2.711 2.711 s 1.216 2.711 2.711 2.711 h 3.915 c 1.495 0 2.711 -1.216 2.711 -2.711 s -1.216 -2.711 -2.711 -2.711 H 10.451 z" />
            </g>
          </svg>
          <span className="text-lg font-semibold text-center">
            Click To Show Your Work
          </span>
        </div>
      </button>      
      )}
      {errorMessage && <div className="text-red-500 mb-2">{errorMessage}</div>}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
          setFilePreview(null);
        }}
        className={`flex items-center p-2 bg-gray-100 rounded ${
          filePreview ? "rounded-t-none" : "rounded"
        }`}
      >
        {paperClip}
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChangeInternal(e.target.files[0])}
          className="hidden"
        />
        <textarea
          ref={textareaRef}
          className="flex-grow p-0 m-0 border-0 focus:outline-none focus:ring-0 focus:border-transparent bg-transparent resize-none max-h-48 min-h-10"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            adjustHeight();
          }}
          placeholder={disabled ? "Select a subject to start chatting" : "Type your message..."}
          rows={1}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <button
          type="submit"
          disabled={disabled || input.length === 0 && !filePreview}
          className={`outline-none disabled:opacity-75 flex focus-visible:outline-none h-8 hover:opacity-70 rounded-full transition-colors w-8 ${
            disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700'
          }`}
        >
          <FaArrowAltCircleUp size={25} color="#5688F7" />
        </button>
      </form>
    </div>
  );
};

export default MessageInput;
