import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const ImageGrid = ({ initialImages = [], formToken, totalCount, user, editType }) => {
  const [images, setImages] = useState(initialImages);
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(initialImages.length < totalCount);
  const observerRef = useRef();
  const [actionCompleted, setActionCompleted] = useState(false); // State to track if action is done

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore && !loading) {
          loadMoreImages();
        }
      },
      { threshold: 1.0 }
    );

    const target = document.getElementById("loading-element");
    if (target) observer.observe(target);

    observerRef.current = observer;
    return () => observer.disconnect();
  }, [hasMore, loading]);

  const loadMoreImages = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get("/chat_images.json", {
        params: { page },
        headers: {
          Authorization: `Bearer ${formToken}`,
        },
      });

      if (response.data.images.length > 0) {
        setImages((prevImages) => [...prevImages, ...response.data.images]);
        setPage((prevPage) => prevPage + 1);
      }

      if (!response.data.next_page || images.length >= totalCount) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more images:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSetAsAction = async (imageId) => {
    const payload = editType === 'avatar' ? { user: { avatar_id: imageId } } : { user: { background_id: imageId } };

    try {
      await axios.patch(
        `/api/v1/users/${user.id}.json`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setActionCompleted(true);
    } catch (error) {
      console.error(`Error setting ${editType}:`, error);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
      {images.map((image) => (
        <div key={image.id} className="rounded overflow-hidden shadow-lg relative">
          <a href={image.chatUrl}>
            <img className="w-full" src={image.imageUrl} alt="User generated" />
          </a>
          <div className="px-6 py-4">
            {/* Conditionally show either the created info or action button */}
            {user && editType ? (
              <button
                onClick={() => handleSetAsAction(image.id)}
                className={`absolute bottom-4 right-4 px-4 py-2 rounded-lg mt-4 ${
                  actionCompleted ? 'bg-gray-500 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'
                } text-white`}
                disabled={actionCompleted}
              >
                {actionCompleted ? `${editType === 'avatar' ? 'Avatar' : 'Background'} Set` : `Set as ${editType === 'avatar' ? 'Avatar' : 'Background'}`}
              </button>
            ) : (
              <>
                <p className="text-gray-500 font-bold text-xl mb-2">Created By {image.createdBy}</p>
                <p className="text-gray-500 text-sm">{image.created_at}</p>
              </>
            )}
          </div>
        </div>
      ))}
      </div>
      {loading && <h3>Loading more images...</h3>}
      {!hasMore && <h3>No more images to load.</h3>}
      {hasMore && (
        <div id="loading-element" className="py-10 text-center text-gray-500">
          Scroll down for more images...
        </div>
      )}
    </div>
  );
};

export default ImageGrid;
